#Footer{
    height: 257px;
    background: #0C0D14;
    margin-top: 52px;
    display: flex;
    align-items: flex-end;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    .container{
        width: 1100px;
        margin: 0 auto;
        .branding{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            
            .name{
                margin-left: 10px;
                font-family: Inter;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 20px;
                /* or 111% */
                color: #FFFFFF;
            }
        }
        .options{
            display: flex;
            justify-content: center;
           height: 120px;
           align-items: center;
            .option{
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height, or 111% */
                /* Default / White */
                color: #FFFFFF;
                margin-right: 24px;
            }
        }
        .footend{
            display: flex;
            justify-content: space-between;
            border-top: 1px solid  #FFFFFF;
            padding: 20px 0;
            .textFoot{
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */
                /* Text / Gray 300 */
                color: #D9DBE1;
                a{
                    position: relative;
                    text-decoration: none;
                    color: #fff;
                    transition: all 0.4s ease-in-out;
                }
                a:after{
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: #fff;
                     transition: all 0.4s ease-in-out;
                }
                a:hover:after{
                    width: 0%;
                }
            }
            .socials{

                img{
                    margin: 0 20px;
                }
            }
        }
    }
}

@media (max-width: 1100px){
    #Footer{
        height: 308px;
       .container{
            width: 87.85vw;
            .options{
               
                .option{
                    font-size: 3.27vw;
                    text-align: center;
                    margin: 0 auto;
                }
            }
            .footend{
                flex-direction: column;
                align-items: center;
                .textFoot{
                    font-size: 3.27vw;
                }
                .socials{
                    margin-top: 14px;
                }
                
            }
       }
    }
}