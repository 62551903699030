#Prelist {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

#Prelist h3 {
  font-size: 32px;
  color: #FFF;
  text-align: center;
  max-width: 80%;
  margin-top: 20px;
}

#Prelist h4 {
  font-size: 28px;
  color: #FFF;
  margin-bottom: 20px;
  text-align: center;
  max-width: 80%;
}

@media(max-width: 450px){
#Prelist h4 {
  font-size: 20px;
}
}

#pass {
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: black;
}

#pass label {
  font-size: 30px;
  color: #FFF;
}

#pass input {
  font-size: 26px;
  border-radius: 10px;
  text-align: center;
}