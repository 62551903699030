@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap);
#navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #0C0D14;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 72px;
  display: flex;
  align-items: center;
  z-index: 99; }
  #navbar .navbar {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    flex-direction: row; }

@-webkit-keyframes ShowUpL {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpL {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@-webkit-keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }
    #navbar .navbar .ShowUpLL {
      -webkit-animation: ShowUpL 1s ease-in-out;
              animation: ShowUpL 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
    #navbar .navbar .ShowUpRR {
      -webkit-animation: ShowUpR 1s ease-in-out;
              animation: ShowUpR 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
    #navbar .navbar .brand {
      display: flex;
      flex-direction: row;
      align-items: center; }
      #navbar .navbar .brand img {
        opacity: 0; }
      #navbar .navbar .brand .title_brand {
        opacity: 0;
        margin-left: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF; }
    #navbar .navbar .options {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 2.22vw; }
      #navbar .navbar .options .Appear.responsive {
        opacity: 1; }
      #navbar .navbar .options .option {
        opacity: 0;
        margin-right: 2.22vw;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        -webkit-font-feature-settings: 'salt' on, 'liga' off;
                font-feature-settings: 'salt' on, 'liga' off;
        color: #FFFFFF; }
        #navbar .navbar .options .option a {
          text-decoration: none;
          color: #FFFFFF;
          transition: all 0.4s ease-in-out; }
        #navbar .navbar .options .option a:hover {
          color: #D54D56; }
    #navbar .navbar .userop {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      margin-right: 10px; }
      #navbar .navbar .userop .login.Appear.responsive {
        opacity: 1; }
      #navbar .navbar .userop .signup.Appear.responsive {
        opacity: 1; }
      #navbar .navbar .userop .login {
        opacity: 0;
        margin-right: 13px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF; }
      #navbar .navbar .userop .signup {
        opacity: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        padding: 10px;
        line-height: 20px;
        color: #FFFFFF;
        background: #1B2949;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px; }

@media (max-width: 1100px) {
  #backgroundhambu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: #0C0D14;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    clip-path: circle(100px at 90% 0%);
    -webkit-clip-path: circle(0vw at 100% 0%); }
  @-webkit-keyframes backgroundhambu {
    from {
      clip-path: circle(100px at 90% 0%);
      -webkit-clip-path: circle(0vw at 100% 0%); }
    to {
      clip-path: circle(100px at 90% 0%);
      -webkit-clip-path: circle(200vw at 100% 0%); } }
  @keyframes backgroundhambu {
    from {
      clip-path: circle(100px at 90% 0%);
      -webkit-clip-path: circle(0vw at 100% 0%); }
    to {
      clip-path: circle(100px at 90% 0%);
      -webkit-clip-path: circle(200vw at 100% 0%); } }
  .backgroundhambu {
    -webkit-animation: backgroundhambu 1s ease-in-out;
            animation: backgroundhambu 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  @-webkit-keyframes ShowUpL {
    from {
      opacity: 0;
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px); }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); } }
  @keyframes ShowUpL {
    from {
      opacity: 0;
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px); }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); } }
  @-webkit-keyframes ShowUpR {
    from {
      opacity: 0;
      -webkit-transform: translateX(50px);
              transform: translateX(50px); }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); } }
  @keyframes ShowUpR {
    from {
      opacity: 0;
      -webkit-transform: translateX(50px);
              transform: translateX(50px); }
    to {
      opacity: 1;
      -webkit-transform: translateX(0px);
              transform: translateX(0px); } }
  .ShowUpL {
    -webkit-animation: ShowUpL 1s ease-in-out;
            animation: ShowUpL 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }
  .ShowUpR {
    -webkit-animation: ShowUpR 1s ease-in-out;
            animation: ShowUpR 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }
  #navbar .navbar .brand img {
    margin-left: 26px; }
  #navbar .navbar .options.responsive.hambu {
    top: 100px;
    flex-direction: column; }
  #navbar .navbar .options.responsive {
    position: fixed;
    display: flex;
    width: 100%;
    color: #fff;
    margin-right: 0px;
    margin-left: 0px; }
    #navbar .navbar .options.responsive .option {
      color: #fff;
      margin: 0px;
      margin-bottom: 60px;
      font-size: 20px;
      opacity: 0; }
  #navbar .navbar .userop.responsive.hambu {
    flex-direction: column;
    top: 450px; }
  #navbar .navbar .userop.responsive {
    position: fixed;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center; }
    #navbar .navbar .userop.responsive .signup.responsive, #navbar .navbar .userop.responsive .login.responsive {
      color: #fff;
      margin-right: 0;
      font-size: 20px;
      margin-bottom: 30px;
      background: none;
      opacity: 0; }
  #navbar .navbar .hambu {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 20px;
    justify-content: center;
    z-index: 99; }
    #navbar .navbar .hambu .line {
      width: 35px;
      height: 4px;
      background-color: #fff; }
  @-webkit-keyframes lineAnim1 {
    from {
      -webkit-transform: rotateZ(0deg) translate(0px, 0px);
              transform: rotateZ(0deg) translate(0px, 0px); }
    to {
      -webkit-transform: rotateZ(45deg) translate(10px, 2px);
              transform: rotateZ(45deg) translate(10px, 2px); } }
  @keyframes lineAnim1 {
    from {
      -webkit-transform: rotateZ(0deg) translate(0px, 0px);
              transform: rotateZ(0deg) translate(0px, 0px); }
    to {
      -webkit-transform: rotateZ(45deg) translate(10px, 2px);
              transform: rotateZ(45deg) translate(10px, 2px); } }
  @-webkit-keyframes lineAnim2 {
    from {
      opacity: 1; }
    to {
      opacity: 0; } }
  @keyframes lineAnim2 {
    from {
      opacity: 1; }
    to {
      opacity: 0; } }
  @-webkit-keyframes lineAnim3 {
    from {
      -webkit-transform: rotateZ(0deg) translate(0px, 0px);
              transform: rotateZ(0deg) translate(0px, 0px); }
    to {
      -webkit-transform: rotateZ(-45deg) translate(10px, -3px);
              transform: rotateZ(-45deg) translate(10px, -3px); } }
  @keyframes lineAnim3 {
    from {
      -webkit-transform: rotateZ(0deg) translate(0px, 0px);
              transform: rotateZ(0deg) translate(0px, 0px); }
    to {
      -webkit-transform: rotateZ(-45deg) translate(10px, -3px);
              transform: rotateZ(-45deg) translate(10px, -3px); } }
    #navbar .navbar .hambu .lineAnim1 {
      -webkit-animation: lineAnim1 1s ease-in-out;
              animation: lineAnim1 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }
    #navbar .navbar .hambu .lineAnim2 {
      -webkit-animation: lineAnim2 1s ease-in-out;
              animation: lineAnim2 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }
    #navbar .navbar .hambu .lineAnim3 {
      -webkit-animation: lineAnim3 1s ease-in-out;
              animation: lineAnim3 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }
    #navbar .navbar .hambu .line:nth-child(2) {
      margin: 5px 0; } }

@keyframes ShowUpL {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    z-index: -99; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    z-index: -99; } }

@-webkit-keyframes ShowUp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ShowUp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#Intro .ShowUpLL {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#Intro .ShowUpRR {
  -webkit-animation: ShowUpR 1s ease-in-out;
          animation: ShowUpR 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#Intro .ShowUpRRE {
  -webkit-animation: ShowUpR 1s ease-in-out;
          animation: ShowUpR 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#Intro .SU1 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

#Intro .SU2 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

#Intro .SU3 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

#Intro .container {
  display: flex;
  width: 1100px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 222px; }
  #Intro .container img {
    margin-top: -20px;
    width: 291px;
    height: 343.11px;
    opacity: 0; }

@-webkit-keyframes LetterAppear {
  0% {
    width: 100%; }
  100% {
    left: 0;
    width: 0%; } }

@keyframes LetterAppear {
  0% {
    width: 100%; }
  100% {
    left: 0;
    width: 0%; } }
  #Intro .container .letterAppear.responsive {
    opacity: 1; }
    #Intro .container .letterAppear.responsive:after {
      -webkit-animation: LetterAppear 1s ease-in-out;
              animation: LetterAppear 1s ease-in-out;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
  #Intro .container .miniT {
    position: relative;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */
    color: #F1505B;
    opacity: 0; }
    #Intro .container .miniT:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #F1505B; }
  #Intro .container .mainText {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 54px;
    line-height: 70px;
    opacity: 0;
    /* or 130% */
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF; }

#Intro .container2 {
  display: flex;
  flex-direction: column;
  width: 1100px;
  margin: 0 auto;
  margin-top: -60px; }
  #Intro .container2 .empezar {
    background: #1B2949;
    border-radius: 6px;
    font-family: Inter;
    width: 197px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-align: center;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    opacity: 0;
    /* Default / White */ }
  #Intro .container2 .badges img:not(:first-child) {
    margin-left: -26px;
    margin-top: 33px; }
  #Intro .container2 .badges img {
    opacity: 0; }
  #Intro .container2 .bottomText {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */
    opacity: 0;
    color: #FFFFFF; }

#Intro .bottom {
  height: 253px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #Intro .bottom img {
    width: 20.67px;
    height: 11.63px; }

@media (max-width: 1100px) {
  #Intro .container.responsive {
    display: flex;
    flex-direction: column;
    width: 87.85vw;
    margin-top: 111px;
    align-items: center;
    text-align: left; }
    #Intro .container.responsive .miniT {
      position: relative;
      font-size: 14px;
      line-height: 32px; }
    #Intro .container.responsive .mainText {
      font-size: 40px;
      line-height: 70px; }
    #Intro .container.responsive img {
      margin-top: 6.77vw;
      width: 54.2vw;
      height: 63.8vw; }
  #Intro .container2.responsive {
    width: 87.85vw;
    margin-top: 15.42vw;
    align-items: center; }
    #Intro .container2.responsive .empezar {
      width: 46.02vw;
      height: 11.21vw;
      font-size: 4.28vw; }
    #Intro .container2.responsive .badges img {
      width: 11.91vw;
      height: 11.91vw; }
    #Intro .container2.responsive .badges img:not(:first-child) {
      margin-left: -6.07vw; }
    #Intro .container2.responsive .bottomText {
      font-size: 3.73vw;
      margin-top: 2.8vw; }
  #Intro .bottom {
    height: 80px;
    margin-bottom: 54px; } }

@keyframes ShowUpL {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    z-index: -99; }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    z-index: -99; } }

@keyframes ShowUp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#AboutUs .SU1 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

#AboutUs .SU2 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

#AboutUs .SU3 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

#AboutUs .ShowUpLL {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

#AboutUs .ShowUpRR {
  -webkit-animation: ShowUpR 1s ease-in-out;
          animation: ShowUpR 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

#AboutUs .ShowUpRRE {
  -webkit-animation: ShowUpR 1s ease-in-out;
          animation: ShowUpR 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#AboutUs .container {
  width: 1100px;
  margin: 0 auto; }
  #AboutUs .container .content .quienes {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */
    color: #F1505B;
    opacity: 0; }
  #AboutUs .container .content .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    /* identical to box height, or 133% */
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    opacity: 0; }
  #AboutUs .container .content .text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    opacity: 0; }
  #AboutUs .container .content .bottomText {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    margin-top: 35px;
    opacity: 0; }
  #AboutUs .container .mint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 172px;
    margin-top: 116px;
    height: 48px;
    background: #1B2949;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    text-align: center;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    /* Default / White */
    color: #FFFFFF;
    opacity: 0; }

@media (max-width: 1100px) {
  #AboutUs .container.responsive {
    width: 87.85vw; }
    #AboutUs .container.responsive .content .quienes {
      font-size: 14px; }
    #AboutUs .container.responsive .content .title {
      font-size: 40px; }
    #AboutUs .container.responsive .content .text {
      font-size: 20px; }
    #AboutUs .container.responsive .mint {
      margin-top: 61px; } }

@keyframes ShowUp {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ShowUpL {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@-webkit-keyframes ShowUpU {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes ShowUpU {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

#ComingSoon .SUu1 {
  -webkit-animation: ShowUpU 1s ease-in-out;
          animation: ShowUpU 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .SUu2 {
  -webkit-animation: ShowUpU 1s ease-in-out;
          animation: ShowUpU 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .ShowUpLL {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .ShowUpRR {
  -webkit-animation: ShowUpR 1s ease-in-out;
          animation: ShowUpR 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .SU1 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .SU2 {
  -webkit-animation: ShowUpL 1s ease-in-out;
          animation: ShowUpL 1s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#ComingSoon .container {
  width: 1100px;
  margin: 0 auto;
  margin-top: 146px; }
  #ComingSoon .container .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */
    text-align: center;
    color: #F1505B;
    opacity: 0; }
  #ComingSoon .container .titleContent {
    width: 650px;
    margin: 0 auto;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 64px;
    /* or 152% */
    text-align: center;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    opacity: 0; }
  #ComingSoon .container .bottomText {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
    opacity: 0; }

#ComingSoon .container2 {
  display: flex;
  width: 1100px;
  margin: 0 auto;
  margin-top: 103px; }
  #ComingSoon .container2 img {
    opacity: 0; }
  #ComingSoon .container2 .skills {
    margin-left: 195px; }
    #ComingSoon .container2 .skills .skill {
      opacity: 0;
      display: flex;
      position: relative;
      width: 646px;
      height: 70px;
      margin-bottom: 16px;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in-out;
      -webkit-perspective: 1000px;
              perspective: 1000px; }
      #ComingSoon .container2 .skills .skill:hover .skillInner {
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg); }
      #ComingSoon .container2 .skills .skill:hover > p {
        opacity: 1; }
      #ComingSoon .container2 .skills .skill:nth-child(2) .skillInner {
        background: #1B2949; }
      #ComingSoon .container2 .skills .skill:nth-child(3) .skillInner {
        background: #283C6B; }
      #ComingSoon .container2 .skills .skill:nth-child(4) .skillInner {
        background: #D54D56; }
      #ComingSoon .container2 .skills .skill p {
        position: absolute;
        top: 1.5vw;
        left: 5vw;
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: #fff;
        opacity: 0;
        width: 80%;
        z-index: 99;
        transition: all 0.4s ease-in-out; }
      #ComingSoon .container2 .skills .skill .skillInner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: -webkit-transform 0.6s;
        transition: transform 0.6s;
        transition: transform 0.6s, -webkit-transform 0.6s;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        background: #111A2F;
        border: 2px solid #12141F;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px; }
        #ComingSoon .container2 .skills .skill .skillInner .skillFront, #ComingSoon .container2 .skills .skill .skillInner .skillBack {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto; }
        #ComingSoon .container2 .skills .skill .skillInner .skillBack {
          -webkit-transform: rotateX(180deg);
                  transform: rotateX(180deg);
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          color: #FFFFFF; }
          #ComingSoon .container2 .skills .skill .skillInner .skillBack p {
            width: 80%;
            opacity: 0; }
      #ComingSoon .container2 .skills .skill .content {
        width: 572px;
        display: flex;
        justify-content: space-between; }
        #ComingSoon .container2 .skills .skill .content .name {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 24px;
          color: #FFFFFF; }
        #ComingSoon .container2 .skills .skill .content .power {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */
          color: #FFFFFF; }

@media (max-width: 1100px) {
  #ComingSoon .container.responsive {
    width: 87.85vw; }
    #ComingSoon .container.responsive .titleContent {
      width: 100%;
      text-align: center;
      font-size: 42px; }
    #ComingSoon .container.responsive .bottomText {
      margin-top: 11px;
      margin: 0 auto;
      width: 57.2vw; }
  #ComingSoon .container2.responsive {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 87.85vw; }
    #ComingSoon .container2.responsive img {
      width: 60.9vw;
      height: 76.16vw; }
    #ComingSoon .container2.responsive .skills {
      margin-left: 0px;
      margin-top: 43px;
      width: 100%; }
      #ComingSoon .container2.responsive .skills .skill {
        width: 100%;
        height: 70px; }
        #ComingSoon .container2.responsive .skills .skill p {
          top: 3vw;
          left: 8vw; }
        #ComingSoon .container2.responsive .skills .skill .content {
          width: 77.57vw; }
          #ComingSoon .container2.responsive .skills .skill .content .name {
            font-size: 20px; }
          #ComingSoon .container2.responsive .skills .skill .content .power {
            font-size: 15px; } }

#GeneralRoad {
  margin-top: 44px; }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpU {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }
  #GeneralRoad .SUu1 {
    -webkit-animation: ShowUpU 1s ease-in-out;
            animation: ShowUpU 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #GeneralRoad .SUu2 {
    -webkit-animation: ShowUpU 1s ease-in-out;
            animation: ShowUpU 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #GeneralRoad .SU1 {
    -webkit-animation: ShowUpL 1s ease-in-out;
            animation: ShowUpL 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #GeneralRoad .SU2 {
    -webkit-animation: ShowUpL 1s ease-in-out;
            animation: ShowUpL 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #GeneralRoad .ShowUpRR {
    -webkit-animation: ShowUpR 1s ease-in-out;
            animation: ShowUpR 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #GeneralRoad .header {
    width: 1100px;
    margin: 0 auto; }
    #GeneralRoad .header .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      /* identical to box height, or 200% */
      text-align: center;
      color: #F1505B;
      opacity: 0; }
    #GeneralRoad .header .subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 42px;
      line-height: 64px;
      /* identical to box height, or 152% */
      text-align: center;
      color: #FFFFFF;
      opacity: 0; }
  #GeneralRoad .steps {
    display: flex;
    flex-direction: column;
    width: 1100px;
    margin: 0 auto;
    margin-top: 80px; }
    #GeneralRoad .steps .step {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #12141F;
      width: 772px;
      padding: 10px;
      border: 2px solid #12141F;
      box-sizing: border-box;
      border-radius: 15px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* or 120% */
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 32px;
      opacity: 0; }
  #GeneralRoad .post {
    display: flex;
    width: 1100px;
    margin: 0 auto;
    margin-top: 64px;
    background: #12141F;
    border-radius: 16px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    justify-content: space-between; }
    #GeneralRoad .post img {
      opacity: 0; }
    #GeneralRoad .post .content {
      margin-left: 45px;
      margin-top: 48px; }
      #GeneralRoad .post .content .title {
        font-family: Inter;
        width: 70%;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 54px;
        color: #FFFFFF;
        opacity: 0; }
      #GeneralRoad .post .content .entrar {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 232px;
        height: 48px;
        background: #1B2949;
        border-radius: 6px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        text-align: center;
        color: #FFFFFF;
        opacity: 0; }
  #GeneralRoad .step:first-child {
    align-self: flex-start; }
  #GeneralRoad .step:nth-child(2) {
    align-self: flex-end; }
  #GeneralRoad .step:nth-child(3) {
    align-self: flex-start; }
  #GeneralRoad .step:nth-child(4) {
    align-self: flex-end; }

@media (max-width: 1100px) {
  #GeneralRoad .header {
    width: 87.85vw; }
  #GeneralRoad .steps {
    width: 87.85vw; }
    #GeneralRoad .steps .step {
      width: 87.61vw;
      align-self: center;
      font-size: 15px; }
  #GeneralRoad .post {
    width: 87.85vw; }
    #GeneralRoad .post .content {
      width: 100%;
      margin: 20px; }
      #GeneralRoad .post .content .title {
        font-size: 31px;
        width: 100%;
        text-align: center; }
      #GeneralRoad .post .content .entrar {
        margin: 0 auto;
        margin-top: 26px; }
    #GeneralRoad .post img {
      display: none; } }

#RoadMap {
  margin-top: 81px; }

@keyframes ShowUpR {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); } }

@keyframes ShowUpU {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }
  #RoadMap .SUu1 {
    -webkit-animation: ShowUpU 1s ease-in-out;
            animation: ShowUpU 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  #RoadMap .SUu2 {
    -webkit-animation: ShowUpU 1s ease-in-out;
            animation: ShowUpU 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  #RoadMap .ShowUpRR {
    -webkit-animation: ShowUpR 1s ease-in-out;
            animation: ShowUpR 1s ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #RoadMap .container .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 64px;
    /* identical to box height, or 183% */
    text-align: center;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
            font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    opacity: 0; }
  #RoadMap .container .subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    /* or 120% */
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px; }
  #RoadMap .container .road {
    width: 933px;
    height: 690px;
    background-image: url(/static/media/Stadium.aeb9cf9c.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    margin: auto; }
    #RoadMap .container .road .items {
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 97%;
      padding-top: 21px; }
      #RoadMap .container .road .items div:not(.item) {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        /* or 120% */
        text-align: center;
        color: #FFFFFF;
        opacity: 0; }
      #RoadMap .container .road .items .item {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #12141F;
        border: 2px solid #12141F;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 90%;
        height: 76px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        /* or 120% */
        text-align: center;
        color: #FFFFFF; }
      #RoadMap .container .road .items .item:not(:first-child) {
        height: 54px;
        padding: 20px; }
      #RoadMap .container .road .items .miniText {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        /* identical to box height, or 178% */
        text-align: center;
        color: #C6C6C6;
        margin-top: 10px;
        padding-bottom: 10px;
        opacity: 0; }

@media (max-width: 1100px) {
  #RoadMap .container .title {
    font-size: 7.47vw;
    margin-bottom: 1vw; }
  #RoadMap .container .road {
    width: 90%;
    height: 1033px;
    border-radius: 25px; }
    #RoadMap .container .road .item.responsive {
      font-size: 15px;
      padding: 10px;
      font-weight: 500;
      height: auto; }
    #RoadMap .container .road .item:not(:first-child).responsive {
      font-size: 15px;
      font-weight: 500;
      padding: 10px;
      height: auto; }
    #RoadMap .container .road .miniText.responsive {
      padding: 10px;
      font-size: 14px; } }

#Footer {
  height: 257px;
  background: #0C0D14;
  margin-top: 52px;
  display: flex;
  align-items: flex-end;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25); }
  #Footer .container {
    width: 1100px;
    margin: 0 auto; }
    #Footer .container .branding {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px; }
      #Footer .container .branding .name {
        margin-left: 10px;
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 20px;
        /* or 111% */
        color: #FFFFFF; }
    #Footer .container .options {
      display: flex;
      justify-content: center;
      height: 120px;
      align-items: center; }
      #Footer .container .options .option {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height, or 111% */
        /* Default / White */
        color: #FFFFFF;
        margin-right: 24px; }
    #Footer .container .footend {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid  #FFFFFF;
      padding: 20px 0; }
      #Footer .container .footend .textFoot {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        /* Text / Gray 300 */
        color: #D9DBE1; }
        #Footer .container .footend .textFoot a {
          position: relative;
          text-decoration: none;
          color: #fff;
          transition: all 0.4s ease-in-out; }
        #Footer .container .footend .textFoot a:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transition: all 0.4s ease-in-out; }
        #Footer .container .footend .textFoot a:hover:after {
          width: 0%; }
      #Footer .container .footend .socials img {
        margin: 0 20px; }

@media (max-width: 1100px) {
  #Footer {
    height: 308px; }
    #Footer .container {
      width: 87.85vw; }
      #Footer .container .options .option {
        font-size: 3.27vw;
        text-align: center;
        margin: 0 auto; }
      #Footer .container .footend {
        flex-direction: column;
        align-items: center; }
        #Footer .container .footend .textFoot {
          font-size: 3.27vw; }
        #Footer .container .footend .socials {
          margin-top: 14px; } }

#Form {
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background: #12141F;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  padding-top: 3%; }

#Form h1 {
  font-size: 54px;
  color: #F1505B;
  font-weight: 800; }

#Form p {
  font-size: 24px;
  color: #FFF;
  width: 40%;
  text-align: center;
  min-width: 350px;
  max-width: 90%; }

#Form form {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; }

#Form form div {
  display: flex;
  flex-direction: column; }

#Form form div label {
  font-size: 24px;
  color: #FFF; }

#Form form div input {
  font-size: 22px;
  z-index: 99999999;
  border-radius: 8px; }

#Form form button {
  color: #12141F;
  background: #FFF;
  padding: 6px 40px 6px 40px;
  border-radius: 20px;
  font-size: 24px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  transition: .7s; }

#closeBtn {
  font-size: 30px;
  font-weight: 800;
  color: #F1505B;
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  left: 10px; }

#spacedTop {
  margin-top: 20px;
  max-width: 80%;
  text-align: center;
  align-self: center; }

#spacedLeft {
  margin-left: 10px;
  width: 20px;
  height: 20px; }

#terms {
  overflow: scroll;
  color: #F1505B; }

#Prelist {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll; }

#Prelist h3 {
  font-size: 32px;
  color: #FFF;
  text-align: center;
  max-width: 80%;
  margin-top: 20px; }

#Prelist h4 {
  font-size: 28px;
  color: #FFF;
  margin-bottom: 20px;
  text-align: center;
  max-width: 80%; }

@media (max-width: 450px) {
  #Prelist h4 {
    font-size: 20px; } }

#pass {
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: black; }

#pass label {
  font-size: 30px;
  color: #FFF; }

#pass input {
  font-size: 26px;
  border-radius: 10px;
  text-align: center; }

#Socials {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

#Socials #icons {
  display: flex;
  height: 160px;
  width: 230px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column; }

#Socials #icons a {
  font-size: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-decoration: none; }

#Socials #icons img {
  width: 30px;
  height: 30px;
  margin-right: 15px; }

#Socials #icons a:hover {
  color: #F1505B; }

#Socials h1 {
  color: #F1505B;
  font-size: 42px;
  width: 500px;
  max-width: 80%;
  text-align: center;
  margin-bottom: 50px; }

body {
  height: 100%;
  background: #0C0D14;
  overflow-x: hidden; }

@-webkit-keyframes Move {
  0% {
    top: 72px; }
  25% {
    top: calc(72px + 350vh);
    left: 30vw; }
  50% {
    top: 700vh;
    left: 0; }
  75% {
    top: calc(72px + 350vh);
    left: 30vw; }
  100% {
    top: 72px;
    left: -563px; } }

@keyframes Move {
  0% {
    top: 72px; }
  25% {
    top: calc(72px + 350vh);
    left: 30vw; }
  50% {
    top: 700vh;
    left: 0; }
  75% {
    top: calc(72px + 350vh);
    left: 30vw; }
  100% {
    top: 72px;
    left: -563px; } }

@-webkit-keyframes Move2 {
  0% {
    top: 631px; }
  25% {
    top: calc(631px + 300vh);
    left: 0; }
  50% {
    top: 700vh;
    left: 30vw; }
  75% {
    top: calc(631px + 300vh);
    left: 0; }
  100% {
    top: 631px;
    left: 326px; } }

@keyframes Move2 {
  0% {
    top: 631px; }
  25% {
    top: calc(631px + 300vh);
    left: 0; }
  50% {
    top: 700vh;
    left: 30vw; }
  75% {
    top: calc(631px + 300vh);
    left: 0; }
  100% {
    top: 631px;
    left: 326px; } }

@-webkit-keyframes Move3 {
  0% {
    top: 1063px; }
  25% {
    top: calc(1063px + 250vh);
    left: 30vw; }
  50% {
    top: 700vh;
    left: 0; }
  75% {
    top: calc(1063px + 250vh);
    left: 30vw; }
  100% {
    top: 1063px;
    left: -516px; } }

@keyframes Move3 {
  0% {
    top: 1063px; }
  25% {
    top: calc(1063px + 250vh);
    left: 30vw; }
  50% {
    top: 700vh;
    left: 0; }
  75% {
    top: calc(1063px + 250vh);
    left: 30vw; }
  100% {
    top: 1063px;
    left: -516px; } }

@-webkit-keyframes Move4 {
  0% {
    top: 2002px; }
  25% {
    top: calc(2002px + 200vh);
    left: 0; }
  50% {
    top: 700vw;
    left: 30vw; }
  75% {
    top: calc(2002px + 200vh);
    left: 0; }
  100% {
    left: 317px; } }

@keyframes Move4 {
  0% {
    top: 2002px; }
  25% {
    top: calc(2002px + 200vh);
    left: 0; }
  50% {
    top: 700vw;
    left: 30vw; }
  75% {
    top: calc(2002px + 200vh);
    left: 0; }
  100% {
    left: 317px; } }

@-webkit-keyframes Move5 {
  0% {
    top: 2698px; }
  25% {
    top: calc(2698px - 200vh);
    left: 30vw; }
  50% {
    top: 0;
    left: 0; }
  75% {
    top: 200vh;
    left: 30vw; }
  100% {
    top: 2698px;
    left: -273px; } }

@keyframes Move5 {
  0% {
    top: 2698px; }
  25% {
    top: calc(2698px - 200vh);
    left: 30vw; }
  50% {
    top: 0;
    left: 0; }
  75% {
    top: 200vh;
    left: 30vw; }
  100% {
    top: 2698px;
    left: -273px; } }

@-webkit-keyframes Move6 {
  0% {
    top: 3545px; }
  25% {
    top: calc(3045px - 200vh);
    left: 0; }
  50% {
    top: 0;
    left: 30vw; }
  75% {
    top: 400vh;
    left: 0; }
  100% {
    top: 3545px;
    left: 298px; } }

@keyframes Move6 {
  0% {
    top: 3545px; }
  25% {
    top: calc(3045px - 200vh);
    left: 0; }
  50% {
    top: 0;
    left: 30vw; }
  75% {
    top: 400vh;
    left: 0; }
  100% {
    top: 3545px;
    left: 298px; } }

@-webkit-keyframes MoveX {
  0% {
    top: 74px; }
  25% {
    top: calc(74px + 150vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(74px + 150vh);
    left: 50vw; }
  100% {
    top: 74px;
    left: -353px; } }

@keyframes MoveX {
  0% {
    top: 74px; }
  25% {
    top: calc(74px + 150vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(74px + 150vh);
    left: 50vw; }
  100% {
    top: 74px;
    left: -353px; } }

@-webkit-keyframes MoveX2 {
  0% {
    top: 631px; }
  25% {
    top: calc(631px + 100vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(74px + 100vh);
    left: 0; }
  100% {
    top: 631px;
    left: 1277px; } }

@keyframes MoveX2 {
  0% {
    top: 631px; }
  25% {
    top: calc(631px + 100vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(74px + 100vh);
    left: 0; }
  100% {
    top: 631px;
    left: 1277px; } }

@-webkit-keyframes MoveX3 {
  0% {
    top: 1063px; }
  25% {
    top: calc(1063px + 150vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(1063px + 150vh);
    left: 50vw; }
  100% {
    top: 1063px;
    left: -335px; } }

@keyframes MoveX3 {
  0% {
    top: 1063px; }
  25% {
    top: calc(1063px + 150vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(1063px + 150vh);
    left: 50vw; }
  100% {
    top: 1063px;
    left: -335px; } }

@-webkit-keyframes MoveX4 {
  0% {
    top: 2002px; }
  25% {
    top: calc(2002px + 150vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(2002px + 150vh);
    left: 0; }
  100% {
    top: 2002px;
    left: 1327px; } }

@keyframes MoveX4 {
  0% {
    top: 2002px; }
  25% {
    top: calc(2002px + 150vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(2002px + 150vh);
    left: 0; }
  100% {
    top: 2002px;
    left: 1327px; } }

@-webkit-keyframes MoveX5 {
  0% {
    top: 2098px; }
  25% {
    top: calc(2098px - 200vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(2098px - 300vh);
    left: 50vw; }
  100% {
    top: 2098px;
    left: -333px; } }

@keyframes MoveX5 {
  0% {
    top: 2098px; }
  25% {
    top: calc(2098px - 200vh);
    left: 50vw; }
  50% {
    top: 400vh;
    left: 0; }
  75% {
    top: calc(2098px - 300vh);
    left: 50vw; }
  100% {
    top: 2098px;
    left: -333px; } }

@-webkit-keyframes MoveX6 {
  0% {
    top: 3045px; }
  25% {
    top: calc(3045px - 300vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(3045px - 300vh);
    left: 0; }
  100% {
    top: 3045px;
    left: 1346px; } }

@keyframes MoveX6 {
  0% {
    top: 3045px; }
  25% {
    top: calc(3045px - 300vh);
    left: 0; }
  50% {
    top: 400vh;
    left: 50vw; }
  75% {
    top: calc(3045px - 300vh);
    left: 0; }
  100% {
    top: 3045px;
    left: 1346px; } }
  body .cir {
    position: relative;
    opacity: .05; }
    body .cir img {
      z-index: -99; }
  body .circle {
    position: absolute;
    left: -353px;
    top: 72px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX 50s infinite;
            animation: MoveX 50s infinite; }
  body .circle2 {
    position: absolute;
    left: 1277px;
    top: 631px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX2 100s infinite;
            animation: MoveX2 100s infinite; }
  body .circle3 {
    position: absolute;
    left: -335px;
    top: 1063px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX3 60s infinite;
            animation: MoveX3 60s infinite; }
  body .circle4 {
    position: absolute;
    left: 1327px;
    top: 2002px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX4 70s infinite;
            animation: MoveX4 70s infinite; }
  body .circle5 {
    position: absolute;
    left: -333px;
    top: 2698px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX5 80s infinite;
            animation: MoveX5 80s infinite; }
  body .circle6 {
    position: absolute;
    left: 1346px;
    top: 3545px;
    width: 35vw;
    height: 35vw;
    -webkit-animation: MoveX6 90s infinite;
            animation: MoveX6 90s infinite; }
  @media (max-width: 1100px) {
    body .circle {
      left: 0;
      width: calc(65vw + 0vw);
      height: calc(65vw + 0vw);
      -webkit-animation: Move 300s infinite;
              animation: Move 300s infinite; }
    body .circle2 {
      left: 30vw;
      width: calc(38.8vw + 0vw);
      height: calc(39.2vw + 0vw);
      -webkit-animation: Move2 300s infinite;
              animation: Move2 300s infinite; }
    body .circle3 {
      left: 0;
      width: calc(65vw + 0vw);
      height: calc(65vw + 0vw);
      -webkit-animation: Move3 300s infinite;
              animation: Move3 300s infinite; }
    body .circle4 {
      left: 30vw;
      top: 2728px;
      width: calc(42.36vw + 0vw);
      height: calc(42.36vw + 0vw);
      -webkit-animation: Move4 300s infinite;
              animation: Move4 300s infinite; }
    body .circle5 {
      left: 0;
      top: 3548px;
      z-index: -99;
      width: calc(49.63vw + 0vw);
      height: calc(49.63vw + 0vw);
      -webkit-animation: Move5 300s infinite;
              animation: Move5 300s infinite; }
    body .circle6 {
      left: 30vw;
      top: 4436px;
      width: calc(30.9vw + 0vw);
      height: calc(30.9vw + 0vw);
      -webkit-animation: Move6 300s infinite;
              animation: Move6 300s infinite; } }

#root {
  overflow-x: hidden; }

html {
  scroll-behavior: smooth; }

* {
  margin: 0;
  padding: 0; }

