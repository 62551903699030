#Socials {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Socials #icons {
  display: flex;
  height: 160px;
  width: 230px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

#Socials #icons a {
  font-size: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-decoration: none;
}

#Socials #icons img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

#Socials #icons a:hover {
  color: #F1505B;
}

#Socials h1 {
  color: #F1505B;
  font-size: 42px;
  width: 500px;
  max-width: 80%;
  text-align: center;
  margin-bottom: 50px;
}