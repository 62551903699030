#RoadMap{
    margin-top: 81px;
    @keyframes ShowUpR {
        from {
            opacity: 0;
            transform: translateX(50px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    @keyframes ShowUpU {
        from {
            opacity: 0;
            transform: translateY(50px);
        }
        to{
            opacity: 1;
            transform: translateY(0px);
        }
    }
    .SUu1{
        animation: ShowUpU 1s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
    }
    .SUu2{
        animation: ShowUpU 1s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
    }
    .ShowUpRR{
        animation: ShowUpR 1s ease-in-out;
        animation-fill-mode: forwards;
       
    }
    .container{
        .title{
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 64px;
            /* identical to box height, or 183% */
            text-align: center;
            font-feature-settings: 'salt' on, 'liga' off;
            color: #FFFFFF;
            opacity: 0;
        }
        .subtitle{
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* or 120% */
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 10px;
            }
        .road{
            width: 933px;
            height: 690px;
            background-image: url("../img/Stadium.png");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 20px;
            margin: auto;
            
            .items{
                 margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 97%;
            padding-top: 21px;
            div:not(.item){
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* or 120% */
                text-align: center;
                color: #FFFFFF;
                opacity: 0;
            }
            .item{
               
                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #12141F;
                border: 2px solid #12141F;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                width: 90%;
                height: 76px;
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* or 120% */
                text-align: center;
                color: #FFFFFF;
            }
            .item:not(:first-child){
                 height: 54px;
                 padding: 20px;
            }

            .miniText{
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 32px;
                /* identical to box height, or 178% */
                text-align: center;
                color: #C6C6C6;
                margin-top: 10px;
                padding-bottom: 10px;
                opacity: 0;
            }
            }
           
            
        }
    }
}

@media (max-width: 1100px){
    #RoadMap{
        .container{
            .title{
                font-size: 7.47vw;
                margin-bottom: 1vw;
            }
            .road{
                width: 90%;
              
                height: 1033px;
                border-radius: 25px;
                .item.responsive{
                    font-size: 15px;
                     padding: 10px;
                     font-weight: 500;
                    height: auto;
                }
                .item:not(:first-child).responsive{
                    font-size: 15px;
                     font-weight: 500;
                    padding: 10px;
                    height: auto;
                }
                .miniText.responsive{
                    padding: 10px;
                    font-size: 14px;
                }
            }
        }
    }
}